<template>
  <v-row class="px-10" dense>
    <v-col rows="12" md="6">
      <v-text-field
        v-model="seller.name"
        :rules="[() => !!seller.name || 'Este campo é obrigatório']"
        label="Nome*"
        placeholder="Digite o nome"
        outlined
        rounded
        dense
      />
      <!-- <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="birthday"
            label="Data de nascimento"
            append-icon="fas fa-calendar-alt"
            v-bind="attrs"
            v-on="on"
            readonly
            outlined
            rounded
            dense
          />
        </template>
        <v-date-picker
          v-model="seller.birthday"
          @change="saveDate"
          :active-picker.sync="activePicker"
          :max="
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10)
          "
          min="1950-01-01"
        />
      </v-menu> -->
      <v-text-field
            v-model="seller.birthday"
            label="Data de Nascimento"
            placeholder="XX/XX/XXXX"
            outlined
            v-mask="'##/##/####'"
            rounded
            dense
          />
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        v-model="seller.email"
        :rules="[
          () => !!seller.email || 'Este campo é obrigatório',
          rules.email,
        ]"
        label="Email*"
        placeholder="exemplo@gmail.com"
        outlined
        rounded
        dense
      />
      <v-text-field
        v-model="seller.contact"
        label="Telefone"
        v-mask="['(##) ####-####', '(##) #####-####']"
        placeholder="(xx)x.xxxx-xxxx"
        type="tel"
        outlined
        rounded
        dense
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    seller: Object
  },
  data: () => ({
    menu: false,
    activePicker: null,
    rules: {
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Insira um email válido";
      },
    },
  }),
  computed: {
    birthday: function () {
      if (this.seller.birthday != null) {
        let BR_dateFormat = this.seller.birthday.split("-");
        BR_dateFormat = BR_dateFormat.reverse();
        BR_dateFormat = BR_dateFormat.join("/");
        return BR_dateFormat;
      } else {
        return "";
      }
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    }
  },
  methods: {
    saveDate(date) {
      this.$refs.menu.save(date);
    }
  }
}
</script>